.loadingplaceholder {
  width: 100%;
  height: 100%;
  background-color: #ccc;
  animation: blink 1.5s linear infinite;
}

@keyframes blink {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}